import React from "react";
import cn from "classnames";
import styles from "./Book.module.sass";
import Icon from "../../../components/Icon";
import { appUrl } from "../../../conts";

import { useTranslation } from "react-i18next";

const Book = () => {
	const { t } = useTranslation();

	const items = [
		{
			title: t("book1"),
			images: "/gfx/person.svg",
			alt: "person",
			content: t("book1Content"),
		},
		{
			title: t("book2"),
			images: "/gfx/chat.svg",
			alt: "chat",
			content: t("book2Content"),
		},
		{
			title: t("book3"),
			images: "/gfx/paper.svg",
			alt: "paper",
			content: t("book3Content"),
		},
	];

	return (
		<div id="funkcje" className={cn("section-bg", styles.section)}>
			<div className={cn("container", styles.container)}>
				<img src="/gfx/fancyBall.gif" alt="William" style={{ alignSelf: "center", justifySelf: "center" }} />
				<div className={styles.wrap}>
					<h2 className={cn("h2", styles.title)}>{t("functions_title")}</h2>
					<div className={styles.info}>{t("functions_info")}</div>
					<div className={styles.list}>
						{items.map((x, index) => (
							<div className={styles.item} key={index}>
								<div className={styles.icon} style={{ backgroundColor: x.color }}>
									<img src={x.images} alt={x.alt} />
								</div>
								<div className={styles.details}>
									<div className={styles.subtitle}>{x.title}</div>
									<div className={styles.content}>{x.content}</div>
								</div>
							</div>
						))}
					</div>

					<div className={styles.btns}>
						<a className={cn("button", styles.button)} href={appUrl}>
							<span>{t("tryForFree")}</span>
							<Icon name="arrow-right" size="10" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Book;
