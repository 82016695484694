import React from "react";
import cn from "classnames";
import styles from "./Quality.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

import { useTranslation } from "react-i18next";
const Quality = () => {
	const { t } = useTranslation();

	const items = [
		{
			title: t("quality1"),
			counter: "4k",
			images: "/gfx/send.svg",
			alt: "user",
			content: t("quality1Content"),
		},
		{
			title: t("quality2"),
			counter: "800+",
			images: "/gfx/person.svg",
			alt: "electrical-plus",
			content: t("quality2Content"),
		},
		{
			title: t("quality3"),
			counter: "20k",
			images: "/gfx/chat.svg",
			alt: "flag",
			content: t("quality3Content"),
		},
		{
			title: t("quality4"),
			counter: "40m",
			images: "/gfx/paper.svg",
			alt: "facebook",
			content: t("quality4Content"),
		},
	];

	return (
		<div className={cn("section-pb", styles.section)}>
			<div className={cn("container", styles.container)}>
				<div className={styles.list}>
					{items.map((x, index) => (
						<ScrollParallax className={styles.item} key={index}>
							<div className={styles.preview}>
								<img src={x.images} alt={x.alt} />
							</div>
							<div className={styles.counter}>{x.counter}</div>
							<div className={styles.title}>{x.title}</div>
							<div className={styles.content}>{x.content}</div>
						</ScrollParallax>
					))}
				</div>
			</div>
		</div>
	);
};

export default Quality;
