import React, { useContext } from "react";
import Select from "react-select";
import { clsx } from "clsx";

import { useLanguage } from "../../LanguageProvider";
import styles from "./LanguageDropdown.module.sass";

const Option = ({ icon, lang }) => (
	<div className={styles.option}>
		<div className={clsx(styles.icon, !lang && styles.fix)}>{icon}</div>
		{lang && <span className={styles.label}>{lang}</span>}
	</div>
);

export const LanguageDropdown = () => {
	const { setLanguage, language, allLanguages } = useLanguage();

	const options = allLanguages.map((lang) => {
		const name = lang.longNameOrg.charAt(0).toUpperCase() + lang.longNameOrg.slice(1);
		return {
			value: lang.code,
			label: <Option icon={lang.flagSVG} lang={name} />,
		};
	});

	const customStyles = {
		control: (provided) => ({
			...provided,
			boxShadow: "none",
			cursor: "pointer",
			marginRight: "18px",
		}),
		container: (provided) => ({
			...provided,
			transition: "all 0.2s ease-in-out",
		}),
		menu: (provided) => ({
			...provided,
			maxHeight: "50vh",
			width: "auto",
			transition: "all 0.2s ease-in-out",
		}),
		hlgWow: (provided) => ({
			...provided,
			transition: "all 0.2s ease-in-out",
			display: "flex",
			flexWrap: "no-wrap",
		}),
	};

	const handleChange = (selectedOption) => {
		if (selectedOption) {
			setLanguage(selectedOption.value);
		}
	};

	const defaultValue = language
		? {
				value: language.code,
				label: <Option icon={language.flagSVG} />,
		  }
		: null;

	return (
		<Select
			closeMenuOnSelect={true}
			value={defaultValue}
			onChange={handleChange}
			options={options}
			styles={customStyles}
			isSearchable={false}
			components={{
				IndicatorSeparator: () => <></>,
				SingleValue: ({ data }) => {
					const selectedLanguage = allLanguages.find((lang) => lang.code === language?.code);
					return selectedLanguage ? <Option icon={selectedLanguage.flagSVG} /> : null;
				},
			}}
			menuPlacement="top"
		/>
	);
};
