import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";
import Icon from "../Icon";
import { appUrl } from "../../conts";

import { useTranslation } from "react-i18next";

const Plan = () => {
	const { t } = useTranslation();

	const options = [
		{
			title: t("plan_title"),
			items: [
				{
					title: t("planItem1"),
					description: t("planItem1Text"),
				},
				{
					title: t("planItem2"),
					description: t("planItem2Text"),
				},
				{
					title: t("planItem3"),
					description: t("planItem3Text"),
				},
				{
					title: t("planItem4"),
					description: t("planItem4Text"),
				},
				{
					title: t("planItem5"),
					description: t("planItem5Text"),
				},
				{
					title: t("planItem6"),
					description: t("planItem6Text"),
				},
				{
					title: t("planItem7"),
					description: t("planItem7Text"),
				},
			],
		},
	];

	const data = [
		{
			title: t("planData1_title"),
			color: "#9757D7",
			description: t("planData1_description"),
			price: t("planData1_price"),
			note: t("planData1_note"),
			button: t("startFreeTrial"),
			options: ["true", "true", "true", "true", "true", "false", "false"],
		},
		{
			title: t("planData2_title"),
			color: "#15A5F0",
			description: t("planData2_description"),
			price: t("planData2_price"),
			note: t("planData2_note"),
			button: t("selectPro"),
			options: ["true", "true", "true", "true", "true", "true", "true"],
		},
	];

	const [more, setMore] = useState([false, false, false]);

	const handleClick = (index) => {
		let newMore = [...more];
		newMore[index] = !more[index];

		setMore(newMore);
	};

	const renderContent = (content) => {
		if (content === "true") {
			return <Icon name="check" size="14" />;
		}
		if (content === "false") {
			return <div className={styles.minus}>-</div>;
		}
		return <div className={styles.minus}>{content}</div>;
	};

	return (
		<div id="cennik" className={cn("section-bg", styles.section)}>
			<div className={cn("container", styles.container)}>
				<div className={cn("stage", styles.stage)}>{t("plan_stage")}</div>
				<h1 className={cn("h1", styles.title)}>{t("plan_header")}</h1>
				<div className={styles.table}>
					<div className={styles.row}>
						<div className={styles.col}>
							<div className={styles.head} />
							<div className={styles.body}>
								{options.map((option, index) => (
									<div className={styles.item} key={index}>
										<div className={styles.category}>{option.title}</div>
										{option.items.map((item, index) => (
											<div className={styles.parameter} key={index}>
												<div className={styles.label}>{item.title}</div>
											</div>
										))}
									</div>
								))}
							</div>
						</div>
						{data.map((type, index) => (
							<div className={styles.col} key={index}>
								<div className={styles.head}>
									<div className={styles.package} style={{ color: type.color }}>
										{type.title}
									</div>
									<div className={styles.description}>{type.description}</div>
									{type.price && (
										<>
											<div className={styles.cost}>
												<span className={styles.price}>{type.price}</span>
											</div>
											<div className={styles.note}>{type.note}</div>
										</>
									)}
								</div>
								<div className={styles.body}>
									<div
										className={cn(styles.more, {
											[styles.active]: more[index],
										})}
										onClick={() => handleClick(index)}
									>
										See all features
										<Icon name="arrow-bottom" size="9" />
									</div>
									<div
										className={cn(styles.list, {
											[styles.visible]: more[index],
										})}
									>
										{options.map((option, optionIndex) => (
											<div className={styles.item} key={optionIndex}>
												{option.items.map((item, itemIndex) => (
													<div className={styles.parameter} key={itemIndex}>
														<div className={styles.label}>{item.title}</div>
														{renderContent(type.options[itemIndex])}
													</div>
												))}
											</div>
										))}
									</div>
									<a
										className={cn({ button: index === 0 }, { button: index === 1 }, { "button-stroke": index === 2 }, styles.button)}
										href={appUrl}
									>
										{type.button}
									</a>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Plan;
