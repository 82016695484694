// src/i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(Backend) // Loads translations using HTTP (default)
	.use(LanguageDetector) // Detects language automatically (optional)
	.use(initReactI18next) // Passes i18n down to react-i18next
	.init({
		fallbackLng: "pl", // Default language
		backend: {
			loadPath: "/locales/{{lng}}/translation.json", // Path to translation files
		},
		react: {
			useSuspense: false, // Set to false to prevent rendering before translations are loaded
		},
		interpolation: {
			escapeValue: false, // React already does escaping
		},
		supportedLngs: [
			"en",
			"de",
			"fr",
			"es",
			"it",
			"nl",
			"pl",
			"uk",
			"ro",
			"hu",
			"pt",
			"sv",
			"nl-BE",
			"cs",
			"sr",
			"sk",
			"hr",
			"ca",
			"da",
			"no",
			"lt",
			"lv",
			"mo",
			"et",
			"sl",
			"fi",
			"ja",
		],
		detection: {
			order: ["navigator", "cookie", "querystring", "localStorage", "htmlTag"],
			caches: ["cookie"],
		},
	});

export default i18n;
