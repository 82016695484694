import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./Footer.module.sass";

const socials = [
	{
		title: "LinkedIn",
		url: "https://www.linkedin.com/company/william-ai/",
	},
	{
		title: "Facebook",
		url: "https://www.facebook.com/william.legalai",
	},
];

const Footer = () => {
	const [visible] = useState(false);

	const { t } = useTranslation();

	const menu = [
		{
			title: t("privacy"),
			url: "/privacy",
		},
		{
			title: t("terms"),
			url: "/terms",
		},
		{
			title: t("cookies"),
			url: "/cookies",
		},
	];

	return (
		<footer className={styles.footer}>
			<div className={styles.body}>
				<div className={cn("container", styles.container)}>
					<div className={styles.col}>
						<div className={styles.box}>
							{/* <Link className={styles.logo} to="/">
                                <Image
                                    className={styles.pic}
                                    src="/images/logo-dark.svg"
                                    srcDark="/images/logo-light.svg"
                                    alt="Fitness Pro"
                                />
                            </Link> */}
							{/* <Theme className={styles.theme} /> */}
						</div>
						<div className={cn(styles.item, { [styles.active]: visible })}>
							<div className={styles.menu}>
								{menu.map((x, index) => (
									<NavLink className={styles.link} activeClassName={styles.active} to={x.url} key={index}>
										{x.title}
									</NavLink>
								))}
							</div>
						</div>
					</div>
					<div className={styles.col}>
						<div className={styles.category}>{t("contact")}</div>
						<div className={styles.info}>
							<p>William sp. z o.o.</p>
							<p>hello@william.legal</p>
							<p>ul. Bóżnicza nr 15</p>
							<p>lok. 6, 61-751 Poznań</p>
							<p>NIP: 7831822613</p>
							<p>Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu</p>
							<p>VIII Wydział Gospodarczy KRS</p>
							<p>Numer KRS: 0000854649</p>
							<p>Kapitał zakładowy: 5000 zł</p>
						</div>
					</div>
					<div className={styles.col}>
						<div className={styles.menu}>
							{socials.map((x, index) => (
								<a className={styles.link} activeClassName={styles.active} href={x.url} key={index}>
									{x.title}
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.foot}>
				<div className={cn("container", styles.container)}>
					<div className={styles.copyright}>William sp. z o.o.</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
