import React from "react";
import cn from "classnames";
import styles from "./Offer.module.sass";
import { appUrl } from "../../conts";

import { useTranslation } from "react-i18next";

const Offer = ({ className }) => {
	const { t } = useTranslation();
	return (
		<div className={cn(className, styles.section)}>
			<div className={cn("container", styles.container)}>
				<h2 className={cn("h1", styles.title)}>{t("offer_title")}</h2>
				<div className={styles.text}>{t("offer_text")}</div>
				<a className={cn("button", styles.button)} href={appUrl}>
					{t("talkToWilliam")}
				</a>
			</div>
		</div>
	);
};

export default Offer;
