import React from "react";
import cn from "classnames";
import styles from "./Intro.module.sass";
import { appUrl } from "../../conts";

import { useTranslation } from "react-i18next";

const Intro = ({ scrollToRef }) => {
	const { t } = useTranslation();
	return (
		<div className={styles.section} ref={scrollToRef}>
			<div className={cn("container", styles.container)}>
				<div className={styles.wrap}>
					<h2 className={cn("h1", styles.title)}>{t("intro_title")}</h2>
					<div className={styles.text}>{t("intro_content")}</div>
					<a className={cn("button", styles.button)} href={appUrl}>
						{t("startFreeTrial")}
					</a>
				</div>
				<img src="/gfx/lawyer-boss.webp" alt="Gloves" style={{ maxWidth: 600 }} />
			</div>
		</div>
	);
};

export default Intro;
