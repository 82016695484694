import React, { useRef } from "react";
import Hero from "./Hero";
import Quality from "./Quality";
import Intro from "../Intro";
import Book from "./Book";
import Lifestyle from "./Lifestyle";
import Testimonials from "../Testimonials";
import Plan from "../Plan";
import Offer from "../Offer";

const Home = () => {
	const scrollToRef = useRef(null);

	return (
		<>
			<Hero scrollToRef={scrollToRef} />
			<Quality />
			<Intro />
			<Book />
			<Lifestyle />
			<Testimonials className="section-bg" />
			<Plan />
			<Offer className="section-border-top" />
		</>
	);
};

export default Home;
