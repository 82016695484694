import React, { useState } from "react";
import cn from "classnames";
import styles from "./Trainer.module.sass";
import Item from "./Item";

import { useTranslation } from "react-i18next";

const Trainer = ({ className }) => {
	const { t } = useTranslation();

	const items = [
		{
			name: "Tadeusz Dębski",
			type: t("testimonials1_type"),
			avatar: "/gfx/lawyer1.webp",
			review: [
				{
					author: "Tadeusz Dębski",
					description: t("testimonials1_type"),
					logo: "/gfx/goldlines.svg",
					image: "/gfx/lawyer1max.webp",
					comment: t("testimonials1_text"),
				},
			],
		},
		{
			name: "Agata Wiśniewska",
			type: t("testimonials2_type"),
			avatar: "/gfx/lawyer2.webp",
			review: [
				{
					author: "Agata Wiśniewska",
					description: t("testimonials2_type"),
					image: "/gfx/lawyer2max.webp",
					comment: t("testimonials2_text"),
				},
			],
		},
		{
			name: "Bartosz Szczepański",
			type: t("testimonials3_type"),
			avatar: "/gfx/lawyer3.webp",
			review: [
				{
					author: "Bartosz Szczepański",
					description: t("testimonials3_type"),
					logo: "/gfx/goldlines.svg",
					image: "/gfx/lawyer3max.webp",
					comment: t("testimonials3_text"),
				},
			],
		},
		{
			name: "Natalia Nowakowska",
			type: t("testimonials4_type"),
			avatar: "/gfx/lawyer4.webp",
			review: [
				{
					author: "Natalia Nowakowska",
					description: t("testimonials4_type"),
					logo: "/gfx/goldlines.svg",
					image: "/gfx/lawyer4max.webp",
					comment: t("testimonials4_text"),
				},
			],
		},
	];

	const [activeIndex, setActiveIndex] = useState(0);

	return (
		<div id="opinie" className={cn(styles.section, className)}>
			<div className={cn("container", styles.container)}>
				<div className={styles.list}>
					{items[activeIndex].review.map((x, index) => (
						<Item item={x} key={index} />
					))}
				</div>
				<div className={styles.nav}>
					{items.map((x, index) => (
						<div
							className={cn(styles.link, {
								[styles.active]: index === activeIndex,
							})}
							onClick={() => setActiveIndex(index)}
							key={index}
						>
							<div className={styles.avatar}>
								<img src={x.avatar} alt="Avatar" />
							</div>
							<div className={styles.details}>
								<div className={styles.man}>{x.name}</div>
								<div className={styles.position}>{x.type}</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Trainer;
