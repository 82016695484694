const localStorageHandler = (key) => {
	// Check if we're running in a browser environment
	if (typeof window === "undefined") {
		return {
			get: () => null,
			set: () => null,
			remove: () => null,
			hasValue: () => false,
			isUndefinedOrNull: () => false,
		};
	}

	const get = () => {
		try {
			const value = localStorage.getItem(key);
			return value ? JSON.parse(value) : null;
		} catch (error) {
			console.error(`Error parsing localStorage key "${key}":`, error);
			return null;
		}
	};

	const set = (value) => {
		try {
			localStorage.setItem(key, JSON.stringify(value));
		} catch (error) {
			console.error(`Error stringifying localStorage key "${key}":`, error);
		}
	};

	const remove = () => {
		localStorage.removeItem(key);
	};

	const isUndefinedOrNull = () => {
		const value = get();
		return value === null || value === undefined;
	};

	const hasValue = (value) => {
		if (value === undefined) {
			return !isUndefinedOrNull();
		}
		return JSON.stringify(get()) === JSON.stringify(value);
	};

	return { get, set, remove, hasValue, isUndefinedOrNull };
};

export const localStorageHelper = {
	languageSystem: localStorageHandler("william_language"),
};
