import Flag from "react-world-flags";
export const appUrl = "https://app.william.legal";
export const termsHTML = `<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Bold'">27.05.2024 REGULAMIN ŚWIADCZENIA USŁUGI WILLIAM</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">ROZDZIAŁ 1</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">POSTANOWIENIA OGÓLNE</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§1</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Przedmiot Regulaminu</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">Niniejszy Regulamin określa zasady i warunki świadczenia usług drogą elektroniczną przez Administratora w ramach serwisu internetowego udostępnianego pod domeną „william.legal” (zwanego dalej „serwisem William”) zgodnie z Ustawą z dnia 18 lipca 2002 r. o</span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">świadczeniu usług drogą elektroniczną (Dz.U. 2020 poz. 344).</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§2</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Definicje</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">Ilekroć w Regulaminie jest mowa o:</span>
	</p>
	<ol class="awlist1" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>Administratorze - należy przez to rozumieć spółkę William sp. z o.o. z siedzibą w Poznaniu przy ul. Bóżniczej 15/6, wpisaną do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod nr 0000854649, legitymującą się NIP 7831822613 oraz posiadającą kapitał zakładowy o wartości 5.000,00 zł (słownie: pięć tysięcy złotych);
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>Użytkowniku - należy przez to rozumieć osobę prawną, osobę ustawową lub pełnoletnią osobę fizyczną korzystającą z serwisu William w celu nawiązania kontaktu z prawnikiem lub zapoznania się z treścią tematycznych wpisów na stronie;
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>Osobie ustawowej - należy przez to rozumieć jednostkę organizacyjną nieposiadającą osobowości prawnej, którym ustawa przyznaje zdolność prawną w rozumieniu art. 33<span style="font-size:7.33pt; "><sup>1 </sup></span>Kodeksu cywilnego;
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>Prawniku - należy przez to rozumieć osobę fizyczną wykonującą zawód adwokata, radcy prawnego, notariusza, komornika, rzecznika patentowego, doradcy podatkowego albo prawnika zagranicznego w rozumieniu Ustawy z dnia 5 lipca 2002r. o świadczeniu przez prawników zagranicznych pomocy prawnej w&#xa0;Rzeczypospolitej Polskiej (Dz. U. Dz.U. 2020 poz. 823) bądź pełnoletnią osobę fizyczną zatrudnioną w&#xa0;charakterze aplikanta, asystenta, stażysty lub innym równorzędnym, a związanym z pomocą w&#xa0;świadczeniu usług prawnych albo inną pełnoletnią osobę fizyczną korzystającą z czatu obsługiwanego przez sztuczną inteligencję;
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>Koncie Prawnika - należy przez to rozumieć utworzony dla Prawnika zbiór danych;
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>Logowaniu - należy przez to rozumieć podanie zgłoszonego podczas rejestracji adresu e-mail oraz hasła w celu uzyskania dostępu do konta,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>Okresie rozliczeniowym - okres udostępnienia poszczególnych usług świadczonych drogą elektroniczną (dostępnych w serwisie William), za jaki została uiszczona należność zgodnie z cennikiem zamieszczonym przez Administratora w serwisie William, na stronie internetowej Administratora lub w załączniku do niniejszego Regulaminu,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>Polityce Prywatności - należy przez to rozumieć Politykę ochrony prywatności przyjętą przez Administratora,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>Google LLC - amerykańska spółka z siedzibą w Mountain View prowadząca przedsiębiorstwo informatyczne i obsługująca platformę Google, w tym m.in. wyszukiwarkę Google.
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§3</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Charakter prawny serwisu</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Informacje zawarte w serwisie William nie są poradami prawnymi. W ramach serwisu William nie są udzielane porady prawne ani usługi pośrednictwa w ich udzielaniu. Informacje przekazywane w ramach serwisu William stanowią jedynie fragment danych pozyskanych z ogólnodostępnych źródeł internetowych i dostosowanych przez sztuczną inteligencję pod zapytanie skierowane przez Prawnika.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Administrator nie świadczy usług prawnych w rozumieniu Rozporządzenia (WE) nr 184/2005 PE i Rady z dnia 12. stycznia 2005r. w sprawie statystyki Wspólnoty w zakresie bilansu płatniczego, międzynarodowego handlu usługami i&#xa0;zagranicznych inwestycji bezpośrednich.
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">ROZDZIAŁ 2</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">ZAWARCIE I ROZWIĄZANIE UMOWY</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§4</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Usługobiorcy</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">1. Podmiotami korzystającymi z serwisu William są Prawnicy.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">2. Prawnikami mogą być tylko pełnoletnie osoby fizyczne. Wyjątkowo użytkownikami mogą być również osoby niepełnoletnie w zakresie, w jakim mogą nabywać prawa i zaciągać zobowiązania zgodnie z przepisami powszechnie obowiązującego prawa.</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§5</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Rejestracja</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">1. W celu skorzystania z dostępnych funkcjonalności serwisu William, Prawnik powinien dokonać rejestracji.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">2. Rejestracja polega na wypełnieniu elektronicznego formularza rejestracyjnego, dostępnego w</span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">ramach serwisu William, poprzez podanie następujących danych:</span>
	</p>
	<ol class="awlist2" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>status zawodowy,
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>imię i nazwisko,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>adres poczty elektronicznej (e-mail),
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>numer telefonu,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>hasło dostępu,
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">oraz złożenie oświadczenia o zapoznaniu się z niniejszym regulaminem oraz Polityką Prywatności i</span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">akceptacji treści tych dokumentów, które to oświadczenie jest składane poprzez zaznaczenie odpowiedniego pola w formularzu elektronicznym, a następnie wpisanie kodu aktywacyjnego, który Administrator wysyła w formie wiadomości SMS na podany w elektronicznym formularzu rejestracyjnym numer telefonu (rejestracja podstawowa).</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">3. Poprzez kliknięcie przycisku „Kontynuuj”, znajdującego się pod elektronicznym formularzem rejestracyjnym, Prawnik potwierdza poprawność danych wpisanych w elektronicznym formularzu rejestracyjnym oraz chęć dokonania rejestracji w serwisie William.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">4. Prawnik może także skorzystać z alternatywnego sposobu rejestracji w serwisie William, który polega na naciśnięciu przycisku „Kontynuuj z Google” i uzupełnieniu danych logowania do konta na platformie Google, obsługiwanej przez Google LLC (rejestracja alternatywna).</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">5. Prawnik korzystając z alternatywnego sposobu rejestracji, wyraża zgodę na udostępnianie Administratorowi następujących danych zgromadzonych na koncie na platformie Google:</span>
	</p>
	<ol class="awlist4" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:16.5pt; text-indent:-16.5pt; text-align:justify">
		<span style="width:7.65pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; - </span><span style="font-family:'Nunito Sans Regular'">imię i nazwisko,</span>
	</li>
	<li class="TreA" style="margin-left:16.5pt; text-indent:-16.5pt; text-align:justify">
		<span style="width:7.65pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; - </span><span style="font-family:'Nunito Sans Regular'">adres poczty elektronicznej (e-mail),</span>
	</li>
	</ol>
	<ol start="3" class="awlist5" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>numer telefonu,
	</li>
	</ol>
	<ol start="4" class="awlist6" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:16.5pt; text-indent:-16.5pt; text-align:justify">
		<span style="width:7.65pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; - </span><span style="font-family:'Nunito Sans Regular'">zdjęcie profilowe.</span>
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">6. W wyniku prawidłowo dokonanej rejestracji Prawnik otrzymuje dostęp do Konta Prawnika, z</span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">którego może korzystać w ramach serwisu William każdorazowo po:</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">1) podaniu zgłoszonego podczas rejestracji adresu e-mail oraz hasła w przypadku rejestracji podstawowej albo </span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">2) przyciśnięciu przycisku „Kontynuuj z Google” i uzupełnieniu danych logowania do konta na platformie Google w przypadku rejestracji alternatywnej.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">7. Prawnik przekazując</span> <span style="font-family:'Nunito Sans Regular'">Administratorowi w toku rejestracji swój numer telefonu, wyraża zgodę na jednorazowe przesłanie mu wiadomości SMS zawierającej kod aktywacyjny do Konta Prawnika w serwisie William, a także na przesyłanie mu wiadomości SMS zawierających kody weryfikacyjne do Konta Prawnika w przypadku wprowadzenia weryfikacji dwuetapowej przy logowaniu.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">8. W przypadku nieotrzymania na podany w elektronicznym formularzu rejestracyjnym numer telefonu wiadomości SMS zawierającej kod aktywacyjny Prawnik może skorzystać z przycisku „Wyślij ponownie” i ponownie uzyskać SMS z kodem aktywacyjnym. Przyciśnięcie przycisku „Wyślij ponownie” oznacza wyrażenie przez Prawnika zgody na ponowne przesłanie mu na podany w elektronicznym formularzu rejestracyjnym numer telefonu wiadomości SMS zawierającej kod aktywacyjny.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§6</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Zawarcie i rozwiązanie umowy z Prawnikiem</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">1. Z chwilą prawidłowego dokonania rejestracji pomiędzy Prawnikiem a Administratorem zostaje zawarta umowa o świadczenie usług droga elektroniczną na czas nieoznaczony, w związku z którą utworzone zostaje Konto Prawnika.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">2. Umowa może zostać rozwiązana przez Prawnika za wypowiedzeniem w każdym czasie i z dowolnego powodu poprzez:</span>
	</p>
	<ol class="awlist7" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>oświadczenie złożone Administratorowi na piśmie albo
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>zgłoszenie chęci rozwiązania umowy w przesłanej na adres poczty elektronicznej Administratora wiadomości e-mail. 
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">3. W przypadku chęci skorzystania ze wskazanego w ust. 2 pkt 2 sposobu rozwiązania umowy należy w tym celu wykorzystać adres poczty elektronicznej wskazany jako główny w Koncie Prawnika.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">4. Złożenie przez Prawnika oświadczenia o rozwiązaniu umowy z Administratorem następuje z</span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">chwilą:</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">1) skutecznego doręczenia Administratorowi oświadczenia, o którym mowa w ust. 2 pkt 1 albo</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">2) zarejestrowania wiadomości e-mail w systemie teleinformatycznym Administratora w</span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">przypadku wskazanym w ust. 2 pkt 2.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">5. Okres wypowiedzenia, o którym mowa w ust. 2, stanowi okres pomiędzy skutecznym złożeniem oświadczenia o rozwiązaniu umowy za wypowiedzeniem a ostatnim dniem okresu, za który uiszczona została stosowna należność zgodnie z cennikiem zamieszczonym w serwisie William przez Administratora. We wskazanym okresie wypowiedzenia Prawnik może korzystać z serwisu William na dotychczasowych zasadach.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">6. Umowa może zostać rozwiązana przez Administratora za wypowiedzeniem w każdym czasie i z dowolnego powodu. Przepisy zawarte w ust. 2, 4 i 5 stosuje się odpowiednio.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">7. Z ważnych przyczyn, za które Administrator odpowiedzialności nie ponosi, umowa może zostać rozwiązana przez Administratora z zachowaniem 7-dniowego terminu wypowiedzenia. Za ważną przyczynę uważa się w szczególności nieprzestrzeganie postanowień Regulaminu lub Polityki Prywatności przez Prawnika. Przepisy zawarte w ust. 2 i 4 stosuje się odpowiednio.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">8. Gdy przyczyną rozwiązania umowy w sposób wskazany w ust. 7 jest jej niewykonywanie lub nienależyte wykonywanie przez Prawnika, Prawnikowi nie przysługuje proporcjonalny zwrot należności uiszczonej za dany okres rozliczeniowy obliczony za czas od dnia rozwiązania umowy do ostatniego dnia tego okresu rozliczeniowego.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">8. W momencie rozwiązania umowy z Prawnikiem usunięte zostaje przypisane do niego Konto Prawnika. W okresie wypowiedzenia wskazanym w ust. 7 wykonywanie umowy zawartej z Prawnikiem zostaje tymczasowo zawieszone, a Prawnik pozbawiony zostaje możliwości aktywnego korzystania z serwisu William przy użyciu swojego konta.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">9. Jeżeli umowa została rozwiązana na podstawie oświadczenia Administratora w sposób wskazany w ust. 7, Prawnik nie może się ponownie zarejestrować bez uprzedniej zgody Administratora. W takim przypadku wypełnienie przez Prawnika elektronicznego formularza rejestracyjnego, dostępnego w</span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">ramach serwisu William, albo skorzystanie z rejestracji alternatywnej jest bezskuteczne do czasu wyrażenia zgody przez Administratora, a między Prawnikiem i Administratorem nie następuje zawarcie umowy, o której mowa w ust. 1.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§7</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Konto Prawnika</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Konto Prawnika zawiera podane lub zatwierdzone przez Prawnika dane Prawnika. Na dane Prawnika obligatoryjnie składają się informacje wskazane w §5 ust. 2 lub §5 ust. 5 w zależności od wybranego sposobu rejestracji. Ponadto dane Prawnika można uzupełnić o nazwę działalności, w ramach której świadczone są usługi prawne.
	</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		W przypadku jakichkolwiek zmian danych umieszczonych w koncie wskazanym w ust. 1, Prawnik zobowiązany jest do niezwłocznego ich zaktualizowania za pośrednictwem stosownego formularza dostępnego na stronach internetowych serwisu William. 
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnik ponosi wszelką odpowiedzialność za szkody wynikające z podania przez niego bądź utrzymywania danych niepełnych, nieaktualnych bądź nieprawdziwych.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnik upoważnia Administratora do posługiwania się nazwą oraz oznaczeniem graficznym Kancelarii Prawnika (logo) albo zdjęciem profilowym przypisanym do konta Google - w przypadku ich przekazania - w celu oznaczenia Konta Prawnika, a także w celu wykonywania usług określonych niniejszym Regulaminem.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnikom nie wolno udostępniać osobom niepowołanym możliwości korzystania z konta wskazanego w ust. 1, w tym ujawniać hasła dostępu do konta. W ramach wypełnienia zobowiązania określonego w zdaniu poprzedzającym Prawnik zobowiązany jest zachować hasło do Konta Prawnika w tajemnicy i zabezpieczyć je przed ujawnieniem. 
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnik jest zobowiązany niezwłocznie poinformować serwis William, jeśli dane dostępowe do konta wskazanego w ust. 1 zostały przejęte przez osoby trzecie i wyjaśnić okoliczności ich przejęcia w celu weryfikacji.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnik nie może korzystać ze swojego konta w sposób niezgodny z&#xa0;charakterem i&#xa0;przeznaczeniem usług świadczonych przez Administratora w&#xa0;ramach serwisu William oraz w sposób zakłócający korzystanie z tych usług, w&#xa0;szczególności celem przesyłania niezamówionej informacji handlowej&#xa0; lub dostarczaniem treści o charakterze bezprawnym.
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">ROZDZIAŁ 3</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">KORZYSTANIE Z SERWISU</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§8</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Wymagania techniczne</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">Korzystanie z serwisu William wymaga dysponowania systemem teleinformatycznym spełniającym co najmniej następujące wymagania techniczne:</span>
	</p>
	<ol class="awlist8" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>dostęp do Internetu,
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>możliwość korzystania ze standardowej przeglądarki internetowej, której ustawienia, w&#xa0;przypadku zamiaru korzystania ze strony szyfrowanej przy logowaniu, powinny umożliwiać obsługę połączenia szyfrowanego,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>dostęp do skonfigurowanej poczty elektronicznej, której adres podano podczas rejestracji podstawowej bądź aktualizacji Konta Prawnika, albo dostęp do konta na platformie Google w przypadku wyboru rejestracji alternatywnej.
	</li>
	</ol>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§9</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Zakres usług</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">1. Administrator udostępnia Prawnikom następujące usługi:</span>
	</p>
	<ol class="awlist9" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>ROZMOWA Z CZATBOTEM - skorzystanie z funkcji konwersacyjnej w ramach dostępnego na stronie internetowej Administratora czatu,
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>IDENTYFIKACJA SPRAWY - rozpoznanie charakteru sprawy na podstawie danych wprowadzonych przez Użytkownika,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>PRZEGLĄDANIE TREŚCI - udostępnienie do wglądu innych treści zamieszczanych w ramach serwisu William,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>DOSTĘP DO DZIAŁU OBSŁUGI KLIENTA - możliwość kontaktu z Działem Obsługi Klienta,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>ELEKTRONICZNA IDENTYFIKACJA - rozpoznanie Prawnika na podstawie danych logowania.
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>HISTORIA ROZMOWY - możliwość uzyskania historii rozmowy z chatbotem na podany adres poczty elektronicznej albo w ramach serwisu William,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>INTERFEJS KONTA PRAWNIKA - dodawanie, zmienianie i usuwanie danych zawartych w Koncie Prawnika (zarządzanie Kontem Prawnika).
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">3. W trakcie świadczenia usług mogą ze względów technicznych wystąpić różne błędy czy ograniczenia funkcjonalności, a nawet czasowa niedostępność serwisu William, co związane jest z wczesną wersją oferowanego oprogramowania działającego w ramach serwisu William. W celu zgłoszenia zauważonych błędów, ograniczenia funkcjonalności lub niedostępności serwisu William Prawnicy mogą kontaktować się z Działem Obsługi Klienta za pośrednictwem poczty elektronicznej (e-mail) lub telefonu. Kosz połączenia telefonicznego jest zgodny z taryfą wybranego przez Prawnika operatora telekomunikacyjnego, a Administrator nie pobiera żadnych dodatkowych opłat za połączenie telefoniczne z Działem Obsługi Klienta.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§12</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Odpłatność usług</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Usługi świadczone przez Administratora na rzecz Prawnika w ramach serwisu William mogą być odpłatne. Usługi świadczone odpłatnie są wyraźnie oznaczone jako odpłatne.
	</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnik zostanie poinformowany o wysokości opłaty przed jej poniesieniem w formie komunikatu wymagającego akceptacji bądź w formie cennika udostępnionego na stronie internetowej Administratora lub załączonego do niniejszego Regulaminu.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnikom wystawiane i dostarczane będą faktury elektroniczne, chyba że Prawnik wyraźnie zażąda wystawiania i dostarczania faktur w formie papierowej. W przypadku wyboru wystawiania i dostarczania faktur w formie papierowej Prawnik zobowiązany jest do pokrycia w formie zaliczkowej (płatność z góry) kosztu wystawienia i wysyłki faktur. Koszt wystawienia i wysyłki faktur zostanie Prawnikowi zaprezentowany w momencie zgłoszenia żądania wystawiania i dostarczania faktur w formie papierowej - dopiero wówczas Prawnik może podjąć wiążącą decyzję co do sposobu wystawienia i wysyłki faktur. Dostarczenie faktur w formie papierowej odbywa się na adres wskazany przez Prawnika.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnik może pobrać fakturę w formie elektronicznej korzystając z dedykowanego przycisku dostępnego w Koncie Prawnika po zalogowaniu. Poprzez kliknięcie dedykowanego przycisku Prawnik wyraża zgodę na otrzymywanie faktur w postaci elektronicznej. Wystawione faktury dostępne są w ramach interfejsu Konta Prawnika bądź przesyłane pocztą elektroniczną na adres poczty elektronicznej Prawnika lub osoby wskazanej w ustawieniach Konta.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnik zobowiązany jest do dokonania płatności z góry za dany okres rozliczeniowy i najpóźniej w terminie wskazanym w zamówieniu lub potwierdzeniu zamówienia. 
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Brak płatności w terminie uprawnia Administratora do zawieszenia świadczenia usług na rzecz Prawnika i/lub rozwiązania umowy o świadczenie usług.
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">ROZDZIAŁ 4</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">TRYB POSTĘPOWANIA REKLAMACYJNEGO</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§13</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnik może złożyć reklamację, jeżeli usługi przewidziane w niniejszym Regulaminie nie są realizowane przez Administratora lub są realizowane niezgodnie z&#xa0;postanowieniami Regulaminu.
	</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Reklamację można złożyć pisemnie, przesyłając ją na adres siedziby Administratora, lub w&#xa0;postaci elektronicznej poprzez przesłanie wiadomości e-mail na adres poczty internetowej Administratora.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Reklamacja powinna zawierać co najmniej:
	</li>
	</ol>
	<ol class="awlist10" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>adres e-mail wskazany przez Prawnika jako główny w Koncie Prawnika, 
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>opis zgłaszanych zastrzeżeń oraz 
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>oczekiwany sposób rozstrzygnięcia sprawy.
	</li>
	</ol>
	<ol start="4" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Jeżeli podane w reklamacji dane lub informacje wymagają uzupełnienia dla prawidłowego rozpatrzenia reklamacji, przed rozpatrzeniem reklamacji Administrator zwróci się do składającego reklamację o jej uzupełnienie we wskazanym zakresie i terminie. 
	</li>
	</ol>
	<ol start="5" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Bezskuteczny upływ terminu wskazanego w ust. 4 powoduje, że reklamacja nie może zostać rozpatrzona i podlega oddaleniu. 
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Czynność wezwania składającego reklamację do uzupełnienia reklamacji przerywa bieg terminu do jej rozpatrzenia. Postanowienie to nie uchybia przepisom prawa bezwzględnie obowiązującym w zakresie, w jakim przyznają one szerszą ochronę konsumentom.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Administrator rozpoznaje reklamację w terminie 30 dni od daty jej otrzymania w prawidłowej postaci. Administrator może odmówić rozpatrzenia reklamacji złożonych po upływie 90 dni od ujawnienia się przyczyn reklamacji.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Odpowiedź na reklamację wysyłana jest wyłącznie na adres e-mail wskazany w reklamacji.
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">ROZDZIAŁ 4</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">ADMINISTRATOR</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§13</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Odpowiedzialność</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">1. Administrator nie ponosi odpowiedzialności za:</span>
	</p>
	<ol class="awlist11" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>prawdziwość i rzetelność informacji podawanych przez Prawników,
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>szkody spowodowane:
	</li>
	</ol>
	<ol class="awlist12" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>nieprawidłowym korzystaniem z serwisu William przez Prawnika,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>działaniem wirusów komputerowych lub oprogramowania zainstalowanego na sprzęcie Prawnika,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>nieprawidłowym funkcjonowaniem sprzętu Prawnika,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>przerwą w działaniu serwisu William wynikającą z przyczyn niezawinionych przez Administratora,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>działaniami lub zaniechaniami Prawników,
	</li>
	<li class="TreA" style="margin-left:18pt; text-indent:-18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		<span style="width:4.8pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0; - </span>nieprawidłowymi informacjami przekazanymi Prawnikom przez chatbota.
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">2. W przypadku, gdy działania Prawnika naruszają postanowienia Regulaminu, przepisy obowiązującego prawa, prawa innych Prawników lub osób trzecich, jak również w innych, uzasadnionych przypadkach, gdy Konto Prawnika wymaga dodatkowej weryfikacji danych, Administrator może:</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">1) zawiesić na czas określony lub nieokreślony Konto Prawnika;</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">2) ograniczyć na czas określony lub nieokreślony funkcjonalność Konta Prawnika w zakresie dostępu do poszczególnych usług;</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">3) uzależnić dalsze korzystanie z serwisu William od potwierdzenia przez Prawnika jego wiarygodności w sposób wskazany przez Administratora.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">3. Niezależnie od zawieszenia Konta Prawnika, Prawnik ponosi pełną odpowiedzialność za swoje działania i zaniechania będące podstawą do zawieszenia wskazanego konta, w</span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">szczególności ponosi pełną odpowiedzialność odszkodowawczą względem Administratora.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">4. W przypadku zastosowania sposobów działania określonych w ust. 2 pkt 1 i 2 z uwagi na dostrzeżone działania Prawnika naruszające postanowienia Regulaminu, przepisy obowiązującego prawa, prawa innych Prawników lub osób trzecich Prawnikowi nie przysługuje zwrot proporcjonalnej części należności uiszczonej przez niego za dany okres rozliczeniowy w odniesieniu do czasu od dnia zastosowania danego sposobu działania do dnia upływu ostatniego dnia tego okresu rozliczeniowego.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§14</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Ochrona danych osobowych</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">Podane przez Prawników dane osobowe są gromadzone i przetwarzane przez Administratora zgodnie z obowiązującymi przepisami prawa oraz zgodnie z Polityką Prywatności i</span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">Polityką Cookies.</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">ROZDZIAŁ 5</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">POSTANOWIENIA KOŃCOWE</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§15</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Naruszenia Regulaminu</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">1. W przypadku rażącego naruszenia przez Prawnika postanowień Regulaminu, przepisów prawa lub dobrych obyczajów, Administrator może rozwiązać umowę z Prawnikiem w trybie natychmiastowym bądź natychmiastowo zawiesić jej wykonywanie w ramach określonego Konta Prawnika. W szczególności dotyczy to sytuacji, w której Prawnik </span><span style="font-family:'Nunito Sans Regular'">&#xa0;</span><span style="font-family:'Nunito Sans Regular'">bez zgody Administratora pobrał lub wykorzystał w jakimkolwiek zakresie dostępne w ramach serwisu William materiały o charakterze tajemnicy przedsiębiorstwa lub podlegające ochronie prawnoautorskiej, w tym zwłaszcza w zakresie wyglądu stron internetowych serwisu William i oprogramowania obsługującego serwis William.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">2. Zabronione jest jakiekolwiek agregowanie i przetwarzanie danych oraz innych informacji dostępnych w serwisie William w celu ich dalszego udostępniania osobom trzecim w ramach innych serwisów internetowych, jak i poza Internetem. Zabronione jest również wykorzystywanie oznaczeń William, w tym charakterystycznych elementów grafiki, bez zgody Administratora. Oznaczenie graficzne „William” (logo) stanowi znak towarowy zarejestrowany na rzecz Administratora.</span>
	</p>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§16</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Zmiany Regulaminu</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Administrator ma prawo jednostronnej zmiany Regulaminu. 
	</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Zmiana staje się skuteczna w terminie wskazanym przez Administratora, który nie może być krótszy niż 7 dni od momentu udostępnienia treści zmienionego Regulaminu.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawnik zostanie powiadomiony o zmianach wskazanych w ust. 1 oraz o konieczności ich akceptacji przy pierwszym logowaniu następującym po tych zmianach lub poprzez wiadomość e-mail przesłaną na wskazany w Koncie Prawnika adres poczty elektronicznej.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Odmowa akceptacji zmian wskazanych w ust. 1 jest równoznaczna z natychmiastowym rozwiązaniem umowy z&#xa0;Administratorem. Jeżeli rozwiązanie umowy w tym trybie następuje w trakcie aktywnego okresu rozliczeniowego, za który Prawnik uiścił stosowną należność zgodnie z cennikiem Administratora, wpłacona kwota podlega stosunkowemu rozliczeniu w oparciu o długość okresu rozliczeniowego i jego wykorzystaną część do czasu rozwiązania umowy, a więc w ten sposób, że za czas od pierwszego dnia okresu rozliczeniowego do dnia rozwiązania umowy Administrator zachowuje prawo do wynagrodzenia za świadczone usługi.
	</li>
	</ol>
	<p class="TreA" style="text-align:justify">
	<span style="font-family:'Nunito Sans Regular'">&#xa0;</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">§17</span>
	</p>
	<p class="TreA" style="text-align:center">
	<span style="font-family:'Nunito Sans Regular'">Pozostałe postanowienia</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Prawem właściwym dla umowy pomiędzy Prawnikiem a Administratorem, której przedmiotem są usługi świadczone przez Administratora w ramach serwisu William, jest prawo polskie.
	</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		W razie uznania niektórych postanowień niniejszego Regulaminu za nieważne w stosownym postępowaniu, pozostałe postanowienia pozostają ważne.
	</li>
	<li class="TreA" style="margin-left:18pt; text-align:justify; font-family:'Nunito Sans Regular'">
		Niniejszy Regulamin obowiązuje od dnia 27. maja 2024 roku i stanowi integralną część umów o świadczenie usług w ramach serwisu William.
	</li>
	</ol>`;

export const privacyHTML = `<p class="Tre" style="text-align:center">
	<strong><span style="letter-spacing:normal">27.05.2024 POLITYKA OCHRONY PRYWATNOŚCI</span></strong>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">ROZDZIAŁ 1</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">POSTANOWIENIA OGÓLNE</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§1</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Przedmiot Polityki</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Niniejsza Polityka Ochrony Prywatności (zwana dalej „Polityką”) określa zasady uzyskiwania, przetwarzania, ochrony oraz usuwania danych osobowych Użytkowników i Prawników.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Poszczególne zwroty mają znaczenie nadane im w treści Regulaminu świadczenia usługi William (zwanego dalej „Regulaminem”), o ile nic innego nie wynika wprost z niniejszego dokumentu.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Danymi osobowymi w rozumieniu niniejszej Polityki są dane osobowe w rozumieniu art. 4 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z&#xa0;przetwarzaniem danych osobowych i&#xa0;w&#xa0;sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (zwanego dalej „RODO”), w tym w&#xa0;szczególności numer IP urządzenia, dane o lokalizacji, identyfikator internetowy oraz informacje gromadzone za pośrednictwem plików cookie oraz innej podobnej technologii.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§2</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Administrator</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">Administratorem danych osobowych jest William sp. z o.o. z siedzibą w Poznaniu przy ul. Bóżniczej 15/6, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań Nowe Miasto i Wilda w Poznaniu VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr 0000854649, legitymująca się NIP 7831822613 oraz posiadająca kapitał zakładowy o wartości 5.000,00 zł (słownie: pięć tysięcy złotych).</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§3</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Inspektor Ochrony Danych</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">Inspektorem Ochrony Danych wyznaczonym przez Administratora jest Mateusz Jąkalak. Kontakt z Administratorem lub Inspektorem Ochrony Danych Osobowych wyznaczonym przez Administratora jest możliwy:</span>
	</p>
	<ol class="awlist1" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>pod adresem poczty elektronicznej (<a href="mailto:iod@william.com.pl" style="text-decoration:none"><span class="Hyperlink0" style="color:#000000">iod@william.com.pl</span></a>) lub
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>pod adresem poczty tradycyjnej (William sp. z o.o., ul. Bóżniczej 15/6, 61-751 Poznań).
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">ROZDZIAŁ 2</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">POZYSKIWANIE DANYCH OSOBOWYCH</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§4</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Dodanie Prawnika</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Prawnik, w celu utworzenia Konta Prawnika, może udostępnić Administratorowi dane osobowe Prawnika za pomocą formularza elektronicznego dostępnego w ramach serwisu William zgodnie z procedurą wskazaną w §5 Regulaminu.
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Poprawne uzupełnienie elektronicznego formularza obsługującego proces rejestracji wymaga podania następujących danych osobowych: 
		</li>
	</ol>
	<ol class="awlist2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>status zawodowy,
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>imię nazwisko,
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>numer telefonu oraz
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>adres poczty elektronicznej (e-mail).
		</li>
	</ol>
	<ol start="3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Prawnik, poprzez wprowadzenie w ramach serwisu William danych osobowych Prawników lub osób trzecich do celów realizowania komunikacji lub korzystania z usług serwisu William, udostępnia dane osobowe tych osób Administratorowi.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Prawnik wprowadzając dane osobowe Prawników lub osób trzecich w ramach serwisu William, powinien być do tego uprawniony, w szczególności powinien posiadać stosowną zgodę osoby, której dane dotyczą.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Jeżeli dochodzi do udostępnienia przez Prawnika danych osobowych Prawników, Administrator staje się administratorem tych danych osobowych i w&#xa0;przypadkach przewidzianych przez prawo, spełnia wtórny obowiązek informacyjny wobec osób, których dane osobowe pozyskane zostały w inny sposób, niż od osoby, której dotyczą (zgodnie z art. 14 RODO).
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§5</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Dobrowolność podania danych osobowych</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Podanie danych osobowych Prawników jest dobrowolne, jednakże jest ono niezbędne do wykonania zawartej z&#xa0;Administratorem umowy. Konsekwencją niepodania tych danych osobowych będzie brak możliwości realizacji umowy o&#xa0;świadczenie usług określonych Regulaminem.
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Podanie danych osobowych Użytkowników jest dobrowolne. W niektórych przypadkach podanie wskazanych w zd. 1 danych (zwłaszcza imienia i nazwiska wraz z adresem e-mail) może być niezbędne w celu wykonania niektórych usług w&#xa0;ramach serwisu William (np.&#xa0;obsługi postępowania reklamacyjnego).
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§6</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Gromadzenie danych w logach systemowych</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Aktywność Użytkownika w ramach systemu William, w tym jego dane osobowe, mogą być rejestrowane w logach systemowych. Zebrane w logach informacje przetwarzane są w&#xa0;związku ze świadczeniem usług. 
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Administrator przetwarza dane zarejestrowane w logach również w celach technicznych. Dane mogą być w&#xa0;szczególności tymczasowo przechowywane i przetwarzane w celu zapewnienia bezpieczeństwa i&#xa0;poprawnego funkcjonowania systemów informatycznych, przykładowo w&#xa0;związku z&#xa0;:
		</li>
	</ol>
	<ol class="awlist4" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wykonywaniem kopii bezpieczeństwa, 
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>testowaniem systemów informatycznych, 
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wykrywaniem nieprawidłowości lub 
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>ochroną przed nadużyciami i atakami.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§7</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Inne przypadki pozyskania danych</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">Jeżeli dochodzi do udostępnienia przez Użytkownika lub Prawnika danych osobowych osób trzecich, Administrator staje się administratorem tych danych osobowych i w</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">przypadkach przewidzianych przez prawo, spełnia wtórny obowiązek informacyjny wobec osób, których dane osobowe pozyskane zostały w inny sposób, niż od osoby, której dotyczą (zgodnie z art. 14 RODO).</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">ROZDZIAŁ 3</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">ZARZĄDZANIE DANYMI OSOBOWYMI</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§8</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Przetwarzanie danych osobowych</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">Dane osobowe Użytkowników i Prawników są przetwarzane przez Administratora zgodnie z</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">przepisami dotyczącymi ochrony danych osobowych, w tym w szczególności z</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">RODO oraz Ustawą z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz. U. 2019 poz. 1781).</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§9</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Cel przetwarzania</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">1. Dane Prawnika Administrator przetwarza w celu:</span>
	</p>
	<ol class="awlist5" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>realizacji umowy o świadczenie usług określonych Regulaminem (na podstawie art. 6 ust. 1 lit.&#xa0;b RODO), a w szczególności do świadczenia usługi drogą elektroniczną oraz pełnego korzystania z serwisu William,
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wypełnienia obowiązku prawnego Administratora (na podstawie art. 6 ust. 1 lit. c i ust. 3 RODO), w szczególności wynikającego z przepisów podatkowych,
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>realizacji prawnie uzasadnionych interesów Administratora (na podstawie art. 6 ust. 1 lit. f RODO), w szczególności do marketingu produktów lub usług własnych,
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>dochodzenia lub zabezpieczenia roszczeń lub obrony przed nimi (na podstawie art. 6 ust. 1 lit.&#xa0;f RODO),
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>prowadzenia przez Administratora działań marketingowych, na podstawie zgody wyrażonej przez Prawnika, dotyczącej świadczenia przez Administratora usługi dostarczania treści newslettera.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">2. Dane Użytkownika Administrator przetwarza w celu:</span>
	</p>
	<ol class="awlist6" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wypełnienia obowiązku prawnego Administratora (na podstawie art. 6 ust. 1 lit. c i ust. 3 RODO), a w szczególności do obsługi reklamacji złożonych przez Użytkowników,
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>dochodzenia lub zabezpieczenia roszczeń lub obrony przed nimi (na podstawie art. 6 ust. 1 lit.&#xa0;f RODO).
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§10</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Zakres Przetwarzanie</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Dane osobowe podawane przez Prawników wykorzystywane są przez Administratora do przesyłania informacji o jego usługach, realizacji usług świadczonych przez Administratora zgodnie z Regulaminem, a także w celach statystycznych i badania rynku.&#xa0;
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Dane osobowe podawane przez Użytkowników wykorzystywane są przez Administratora do realizacji usług świadczonych przez Administratora zgodnie z Regulaminem (w tym obsługi postępowań reklamacyjnych), a także w celach statystycznych, badania rynku oraz badania zachowań i&#xa0;preferencji Użytkowników z przeznaczeniem wyników tych badań na potrzeby polepszenia jakości usług świadczonych przez Administratora.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Administrator wykorzystuje adresy IP zbierane w trakcie połączeń internetowych w celach:
		</li>
	</ol>
	<ol class="awlist7" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>ochrony interesów podmiotów korzystających z serwisu William, 
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wyjaśnienia stanu faktycznego przy rozpatrywaniu sporów, analizie nieprawidłowości lub zwalczaniu nadużyć oraz 
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>technicznych, związanych z administracją serwerami. 
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">Ponadto adresy IP służą do zbierania ogólnych, statystycznych informacji demograficznych (np. o</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">regionie, z którego następuje połączenie).</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§11</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Czas Przetwarzania</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">1. Dane osobowe, przetwarzane w celu realizacji umowy o świadczenie usług określonych Regulaminem oraz w celu wypełniania obowiązku prawnego, będą przechowywane przez okres obowiązywania umowy, a po jego upływie przez okres niezbędny do:</span>
	</p>
	<ol class="awlist8" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>obsługi klientów (np. obsługi reklamacji);
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>zabezpieczenia lub dochodzenia roszczeń;
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wypełnienia obowiązku prawnego Administratora.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">2. Dane osobowe przetwarzane na potrzeby marketingu produktów lub usług własnych na podstawie uzasadnionego interesu prawnego, będą przetwarzane do czasu zgłoszenia sprzeciwu przez osobę, której dane dotyczą.</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">3. Dane przetwarzane na podstawie wyrażonej zgody są przetwarzane do czasu jej wycofania.</span><span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">4. Po upływie okresu przetwarzania, dane są nieodwracalnie usuwane lub anonimizowane.</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§12</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Ochrona danych</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Dane osobowe Użytkowników i Prawników są chronione przez Administratora przed ich udostępnieniem osobom nieupoważnionym, a także innymi przypadkami ich ujawnienia albo utraty oraz przed zniszczeniem lub nieuprawnioną modyfikacją wskazanych danych i&#xa0;informacji, poprzez stosowanie odpowiednich zabezpieczeń organizacyjnych, a także zabezpieczeń o&#xa0;charakterze technicznym i programistycznym, w szczególności systemów szyfrowania danych. 
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Hasła są szyfrowane w sposób uniemożliwiający ich odczytanie przez Administratora i&#xa0;osoby działające na jego rzecz.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Przesyłanie danych do i od Administratora w ramach serwisu William odbywa się poprzez zastosowanie bezpiecznego protokołu SSL. 
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Administrator nie odpowiada za transmisję danych, która odbywa się w ramach niezależnych od Administratora systemów poczty elektronicznej, w ramach których Użytkownik czy Prawnik nadaje lub odbiera wiadomość.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Pełen dostęp do baz danych posiadają jedynie osoby należycie uprawnione przez Administratora.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§13</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Zautomatyzowane przetwarzanie a podejmowanie decyzji</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Dane osobowe Użytkowników i Prawników są przetwarzane w ramach serwisu William w&#xa0;sposób zautomatyzowany (w tym w&#xa0;formie profilowania).
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Profilowanie danych osobowych przez serwis William polega na przetwarzaniu danych&#xa0; osobowych Prawników i Użytkowników poprzez wykorzystywanie ich do oceny niektórych informacji o osobach korzystających z serwisu, w szczególności do celów:
		</li>
	</ol>
	<ol class="awlist9" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>analizy lub prognozy osobistych preferencji oraz zainteresowań Użytkowników i Prawników, 
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wewnętrznej statystyki, 
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>oceny świadczonych usług (w tym ich funkcjonalności).
		</li>
	</ol>
	<ol start="3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Administrator nie stosuje podejmowania decyzji na podstawie wyłącznie zautomatyzowanego przetwarzania, w tym&#xa0;profilowania, o&#xa0;którym mowa w art. 22 ust. 1 i 4 RODO, wywołującego skutki prawne wobec Użytkownika czy Prawnika lub w podobny sposób istotnie na niego wpływającego.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§14</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Udostępnianie i przekazywanie danych</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Dane Prawników lub Użytkowników mogą być udostępniane podmiotom uprawnionym do ich otrzymania na mocy obowiązujących przepisów prawa.
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Dane osobowe Prawników lub Użytkowników mogą być także przekazywane wskazanym przez Administratora podmiotom przetwarzającym dane osobowe na zlecenie Administratora, to jest partnerom świadczącym:
		</li>
	</ol>
	<ol class="awlist10" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>usługi techniczne (takie jak usługi hostingowe, usługi dotyczące rozwijania i utrzymywania systemów informatycznych oraz serwisów internetowych),
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>usługi marketingowe,
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>usługi księgowości, rachunkowości,
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>usługi doradcze, konsultacyjne,
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>usługi windykacyjne.
		</li>
	</ol>
	<ol start="3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		W przypadkach niewskazanych przez Administratora bądź przepisy prawa dane osobowe Prawników lub Użytkowników mogą być przekazywane podmiotom trzecim tylko za zgodą osoby, której dotyczą. Zgoda może zostać wyrażona również poprzez odpowiednie celowe działanie, w tym w szczególności podmiotom pośredniczącym w płatnościach w serwisie William.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		W przypadku uzyskania zgody Prawnika lub Użytkownika jego dane mogą zostać także udostępnione innym podmiotom do ich własnych celów, w tym celów marketingowych oraz przesyłania treści handlowych droga elektroniczną.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Dane osobowe nie będą przekazywane do państwa trzeciego lub organizacji międzynarodowej bez uprzedniego spełnienia warunków takiego przekazania wynikających z&#xa0;obowiązujących przepisów prawa, w tym zastosowania odpowiednich zabezpieczeń, zgodnie z art. 44-46 RODO.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">ROZDZIAŁ 4</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">KONTROLA PRZETWARZANIA</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§15</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Uprawnienie odnoszące się do danych osobowych</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Osoba, której dane osobowe są przetwarzane, posiada prawo:
		</li>
	</ol>
	<ol class="awlist11" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>dostępu do treści swoich danych, 
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>uzyskania kopii swoich danych, 
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>sprostowania swoich danych, 
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>usunięcia swoich danych, 
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>ograniczenia przetwarzania swoich danych, 
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>przenoszenia swoich danych; 
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wniesienia sprzeciwu wobec przetwarzania swoich danych na podstawie uzasadnionego interesu Administratora lub wobec przetwarzania ich w celu marketingu bezpośredniego,
		</li>
		<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>cofnięcia zgody na przetwarzanie w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.&#xa0;
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Skorzystania z uprawnień wskazanych powyżej w ust. 1, jest możliwe poprzez kontakt z&#xa0;Inspektorem Ochrony Danych (adres e-mail: iod@william.com.pl).
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Wniosek powinien wskazywać, czego dotyczy żądanie, a w szczególności z&#xa0;jakiego uprawnienia chce skorzystać wnioskodawca, jakiego procesu przetwarzania dotyczy żądanie oraz jaki jest oczekiwany sposób spełnienia jej żądania.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Jeżeli Administrator nie będzie w stanie ustalić treści żądania lub zidentyfikować osoby składającej wniosek w oparciu o dokonane zgłoszenie, zwróci się do wnioskodawcy o&#xa0;dodatkowe informacje.
		</li>
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Odpowiedź na zgłoszenia zostanie udzielona w ciągu miesiąca od jego otrzymania. W razie konieczności przedłużenia tego terminu, Administrator poinformuje wnioskodawcę o&#xa0;przyczynach takiego przedłużenia.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§16</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Prawdziwość udostępnianych danych</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">Prawnicy oraz Użytkownicy są zobowiązani do wskazywania danych pełnych, aktualnych i</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">prawdziwych.</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§17</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Zgłaszanie naruszeń</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">W przypadku uznania, że przetwarzanie danych osobowych narusza przepisy RODO lub inne przepisy dotyczące ochrony danych osobowych, osoba, której dane dotyczą, może złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych.</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">ROZDZIAŁ 5</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">ZAKOŃCZENIE PRZETWARZANIA</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§18</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Żądanie zaprzestania przetwarzania danych</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Prawnik lub Użytkownik ma prawo w każdej chwili zrezygnować z otrzymywania wiadomości elektronicznych oraz żądać zaprzestania przetwarzania danych osobowych w tym zakresie. Stosowne żądanie należy przesłać na adres Inspektora Ochrony Danych.
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline">
		Rezygnacja dokonana zgodnie z ust. 1 przez Prawnika powoduje usunięcie jego Konta Prawnika oraz zaprzestanie przetwarzania jego danych osobowych.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
	<strong><span style="letter-spacing:normal">Zasady przetwarzania Waszych danych osobowych</span></strong>
	</p>
	<p class="Tre" style="text-align:center">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; font-weight:bold; letter-spacing:normal; vertical-align:baseline">
		Kto odpowiada za&#xa0;przetwarzanie Waszych danych osobowych?
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Administratorem Waszych danych osobowych jest William sp. z o.o. z siedzibą w Poznaniu przy ul. Bóżniczej 15/6, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań Nowe Miasto i Wilda w Poznaniu VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr 0000854649, legitymująca się NIP 7831822613 oraz posiadająca kapitał zakładowy o wartości 5.000,00 zł (słownie: pięć tysięcy złotych).</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Inspektorem Ochrony Danych jest Mateusz Jąkalak. Kontakt z Administratorem lub Inspektorem Ochrony Danych Osobowych jest możliwy:</span>
	</p>
	<ol class="awlist1" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>pod adresem poczty elektronicznej (<a href="mailto:iod@william.com.pl" style="text-decoration:none"><span class="Hyperlink0" style="color:#000000">iod@william.com.pl</span></a>) lub
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>pod adresem poczty tradycyjnej (William sp. z o.o., ul. Bóżniczej 15/6, 61-751 Poznań).
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<ol start="2" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; font-weight:bold; letter-spacing:normal; vertical-align:baseline">
		Skąd mamy Wasze dane osobowe?
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Dane osobowe Użytkowników i Prawników otrzymujemy od nich samych na podstawie ich zgody i zaakceptowanego Regulaminu świadczenia usługi William.</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<ol start="3" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; font-weight:bold; letter-spacing:normal; vertical-align:baseline">
		Czy musicie podawać nam Wasze dane osobowe?
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Aby móc zawrzeć i wykonać zawartą z</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">Prawnikiem umowę, a tym samym świadczyć usługę, wymagamy podania następujących danych osobowych:</span>
	</p>
	<ol class="awlist2" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>status zawodowy,
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>imię nazwisko,
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>numer telefonu oraz
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>adres e-mail,
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>specjalizacja.
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Podanie danych przez Prawnika jest dobrowolne, ale wymagane. Jeśli nie podasz tych danych osobowych, niestety nie będziemy mogli zawrzeć z Tobą umowy, a w konsekwencji nie będziesz mógł korzystać z serwisu William. </span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Natomiast podanie danych przez Użytkownika jest</span><span style="letter-spacing:normal">&#xa0; </span><span style="letter-spacing:normal">całkowicie dobrowolne. Jeśli nie podasz Nam żadnych danych osobowych, dalej będziesz mógł korzystać z serwisu William. Pamiętaj jednak, że jeżeli zamierzasz złożyć reklamację musisz podać nam co najmniej swój adres e-mail.</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<ol start="4" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; font-weight:bold; letter-spacing:normal; vertical-align:baseline">
		W&#xa0;jakim celu i&#xa0;na&#xa0;jakiej podstawie przetwarzamy Wasze dane osobowe?
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Dane osobowe Użytkowników i Prawników są przetwarzane przez Nas zgodnie z</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">przepisami dotyczącymi ochrony danych osobowych, w tym w szczególności z</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">RODO* oraz Ustawą z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz. U. 2019 poz. 1781).</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Dane osobowe Prawników przetwarzamy w celu:</span>
	</p>
	<ol class="awlist4" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>realizacji umowy o świadczenie usług w ramach serwisu William (na podstawie art. 6 ust. 1 lit.&#xa0;b RODO), a w szczególności do:
	</li>
	</ol>
	<p class="Tre" style="margin-left:12pt; text-indent:-12pt; text-align:justify">
	<span style="font-size:13pt; letter-spacing:normal; vertical-align:2pt">-</span><span style="width:7.67pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">świadczenia usługi drogą elektroniczną, </span>
	</p>
	<p class="Tre" style="margin-left:12pt; text-indent:-12pt; text-align:justify">
	<span style="font-size:13pt; letter-spacing:normal; vertical-align:2pt">-</span><span style="width:7.67pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">pełnego korzystania z serwisu William oraz </span>
	</p>
	<p class="Tre" style="margin-left:12pt; text-indent:-12pt; text-align:justify">
	<span style="font-size:13pt; letter-spacing:normal; vertical-align:2pt">-</span><span style="width:7.67pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">obsługi zgłoszeń, które do nas kierujesz;</span>
	</p>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wypełnienia obowiązków wynikających z prawa (na podstawie art. 6 ust. 1 lit. c i ust. 3 RODO), w szczególności:
	</li>
	</ol>
	<p class="Tre" style="margin-left:12pt; text-indent:-12pt; text-align:justify">
	<span style="font-size:13pt; letter-spacing:normal; vertical-align:2pt">-</span><span style="width:7.67pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal"> wynikających z przepisów podatkowych,</span>
	</p>
	<p class="Tre" style="margin-left:12pt; text-indent:-12pt; text-align:justify">
	<span style="font-size:13pt; letter-spacing:normal; vertical-align:2pt">-</span><span style="width:7.67pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">obsługi reklamacji;</span>
	</p>
	<ol start="3" class="awlist5" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>realizacji naszych prawnie uzasadnionych interesów (na podstawie art. 6 ust. 1 lit. f RODO), w&#xa0;szczególności do marketingu usług własnych,
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>dochodzenia lub zabezpieczenia roszczeń lub obrony przed nimi (na podstawie art. 6 ust. 1 lit.&#xa0;f RODO),
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>prowadzenia działań marketingowych na podstawie wyrażonej zgody dotyczącej dostarczania newslettera.
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Dane osobowe Użytkowników przetwarzamy w celu:</span>
	</p>
	<ol class="awlist6" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wypełnienia obowiązków wynikających z prawa (na podstawie art. 6 ust. 1 lit. c i ust. 3 RODO), a w szczególności do rozpatrzenia złożonych przez Was reklamacji,
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>dochodzenia lub zabezpieczenia roszczeń lub obrony przed nimi (na podstawie art. 6 ust. 1 lit.&#xa0;f RODO).
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<ol start="5" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; font-weight:bold; letter-spacing:normal; vertical-align:baseline">
		Jak długo zamierzamy przetwarzać Wasze dane osobowe?
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Dane osobowe, przetwarzane w celu dostarczania Wam usług określonych Regulaminem oraz w</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">celu wypełniania obowiązków wynikających z prawa, będą przechowywane do czasu zakończenia przez Was korzystania z serwisu William, a potem przez okres niezbędny do:</span>
	</p>
	<ol class="awlist7" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>obsługi klientów (np. obsługi reklamacji);
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>zabezpieczenia lub dochodzenia roszczeń;
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wypełnienia obowiązków wynikających z prawa.
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Dane osobowe przetwarzane na potrzeby marketingu naszych usług na podstawie uzasadnionego interesu prawnego, będą przetwarzane do czasu zgłoszenia przez</span><span style="letter-spacing:normal">&#xa0; </span><span style="letter-spacing:normal">Was sprzeciwu. Dane przetwarzane na podstawie wyrażonej zgody są przetwarzane do czasu jej wycofania.</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">Po upływie okresu przetwarzania, dane są nieodwracalnie usuwane lub anonimizowane.</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Twoje dane osobowe będą przetwarzane w środowisku informatycznym, co oznacza, że mogą być także tymczasowo przechowywane i przetwarzane w celu zapewnienia bezpieczeństwa i</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">poprawnego funkcjonowania systemów informatycznych (np. w</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal"> związku z wykonywaniem kopii bezpieczeństwa, testami zmian w systemach informatycznych, wykrywania nieprawidłowości lub ochroną przed nadużyciami i atakami).</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<ol start="6" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; font-weight:bold; letter-spacing:normal; vertical-align:baseline">
		Komu&#xa0;przekazujemy Wasze dane osobowe?
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Dane Użytkowników i Prawników przekazujemy podmiotom wspierającym nas w świadczeniu usług drogą elektroniczną, czyli takim, które zapewniają usługi:</span>
	</p>
	<p class="Tre" style="margin-left:9pt; text-indent:-9pt; text-align:justify">
	<span style="font-family:'Arial Unicode MS'; letter-spacing:normal">•</span><span style="width:5.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">płatnicze, </span>
	</p>
	<p class="Tre" style="margin-left:9pt; text-indent:-9pt; text-align:justify">
	<span style="font-family:'Arial Unicode MS'; letter-spacing:normal">•</span><span style="width:5.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">kredytowe, </span>
	</p>
	<p class="Tre" style="margin-left:9pt; text-indent:-9pt; text-align:justify">
	<span style="font-family:'Arial Unicode MS'; letter-spacing:normal">•</span><span style="width:5.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">ubezpieczeniowe, </span>
	</p>
	<p class="Tre" style="margin-left:9pt; text-indent:-9pt; text-align:justify">
	<span style="font-family:'Arial Unicode MS'; letter-spacing:normal">•</span><span style="width:5.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">konsultingowe,</span>
	</p>
	<p class="Tre" style="margin-left:9pt; text-indent:-9pt; text-align:justify">
	<span style="font-family:'Arial Unicode MS'; letter-spacing:normal">•</span><span style="width:5.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">audytowe, </span>
	</p>
	<p class="Tre" style="margin-left:9pt; text-indent:-9pt; text-align:justify">
	<span style="font-family:'Arial Unicode MS'; letter-spacing:normal">•</span><span style="width:5.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">obejmujące wsparcie obsługi Użytkowników, </span>
	</p>
	<p class="Tre" style="margin-left:9pt; text-indent:-9pt; text-align:justify">
	<span style="font-family:'Arial Unicode MS'; letter-spacing:normal">•</span><span style="width:5.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">techniczne m.in. hostingowe, </span>
	</p>
	<p class="Tre" style="margin-left:9pt; text-indent:-9pt; text-align:justify">
	<span style="font-family:'Arial Unicode MS'; letter-spacing:normal">•</span><span style="width:5.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">dotyczące rozwijania i utrzymywanie systemów informatycznych i serwisów internetowych,</span>
	</p>
	<p class="Tre" style="margin-left:9pt; text-indent:-9pt; text-align:justify">
	<span style="font-family:'Arial Unicode MS'; letter-spacing:normal">•</span><span style="width:5.15pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0; </span><span style="letter-spacing:normal">wspierające promocję ofert (np. współpraca w ramach kampanii marketingowych). </span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Możemy również przekazać Wasze dane osobowe organom publicznym np. walczącym z</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">oszustwami i nadużyciami dla celów postępowań karnych.</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<ol start="7" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; font-weight:bold; letter-spacing:normal; vertical-align:baseline">
		Czy&#xa0;Wasze dane osobowe będą wysyłane poza UE?
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Wasze dane osobowe nie są i nie bedą przekazywane poza terytorium Unii Europejskiej.</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<ol start="8" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; font-weight:bold; letter-spacing:normal; vertical-align:baseline">
		Czy&#xa0;Wasze dane osobowe będą profilowane?
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Wasze dane osobowe będą przetwarzane w sposób zautomatyzowany (w tym w</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">formie profilowania), jednakże nie będzie to wywoływać wobec Was żadnych skutków prawnych lub w</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">podobny sposób istotnie wpływać na Waszą sytuację.</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Profilowanie danych osobowych przez serwis William polega na przetwarzaniu Waszych danych (również w sposób zautomatyzowany), poprzez wykorzystywanie ich do oceny niektórych informacji o Was, w szczególności do celów analizy lub prognozy osobistych preferencji oraz zainteresowań Użytkowników i Prawników, a także w celach wewnętrznej statystyki, oceny świadczonych usług (w tym ich funkcjonalności).</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Akceptując nasz Regulamin i Politykę Prywatności zgadzasz się na przetwarzanie swoich danych w sposób automatyczny (w tym w</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">formie profilowania).</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<ol start="9" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; font-weight:bold; letter-spacing:normal; vertical-align:baseline">
		Jakie macie uprawnienia w zakresie przetwarzanych danych?
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Jako Użytkownicy (lub Prawnicy) macie prawo do:</span>
	</p>
	<ol class="awlist8" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>dostępu do treści swoich danych, 
	</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>uzyskania kopii swoich danych, 
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>sprostowania swoich danych, 
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>usunięcia swoich danych, 
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>ograniczenia przetwarzania swoich danych, 
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>przenoszenia swoich danych; 
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>wniesienia sprzeciwu wobec przetwarzania swoich danych na podstawie uzasadnionego interesu Administratora lub wobec przetwarzania ich w celu marketingu bezpośredniego,
	</li>
	<li class="Tre" style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline">
		<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - </span>cofnięcia zgody na przetwarzanie w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.&#xa0;
	</li>
	</ol>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Jeżeli masz pytania dotyczące swoich danych osobowych, chcesz skorzystać ze swojego prawa lub masz jakiekolwiek inne wątpliwości – skontaktuj się z naszym Inspektorem Ochrony Danych.</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">*RODO to Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">przetwarzaniem danych osobowych i</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">w</span><span style="letter-spacing:normal">&#xa0;</span><span style="letter-spacing:normal">sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:justify">
	<span style="letter-spacing:normal">Odpowiedzi na te i więcej pytań znajdziecie w naszej Polityce Ochrony Danych dostępnej na naszej stronie.</span>
	</p>
	`;

export const cookiesHTML = `
	<div>
	<p class="Tre" style="text-align:center">
		<strong>
			<span style="letter-spacing:normal">
			27.05.2024 POLITYKA CIASTECZEK
			</span>
		</strong>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§1</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Przedmiot Polityki</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline"
		>
			Niniejsza Polityka Ciasteczek (zwana dalej „Polityką”)
			określa zasady wykorzystywania Ciasteczek (ang. cookies) w
			ramach serwisu William.
		</li>
		<li
			class="Tre"
			style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline"
		>
			Poszczególne zwroty mają znaczenie nadane im w treści
			Regulaminu świadczenia usługi William (zwanego dalej
			„Regulaminem”), o ile nic innego nie wynika wprost z
			niniejszego dokumentu.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§2</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Definicje</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">
			Ilekroć w Regulaminie jest mowa o:
		</span>
	</p>
	<ol class="awlist1" style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			Ciasteczkach - należy przez to rozumieć dane informatyczne,
			w szczególności pliki tekstowe, zapisywane i przechowywane w
			urządzeniach końcowych Użytkownika lub Prawnika. Pliki te
			umożliwiają rozpoznanie urządzenia Użytkownika lub Prawnika
			oraz odpowiednie dostosowanie strony internetowej do
			indywidualnych preferencji i oczekiwań Użytkownika lub
			Prawnika, a więc pozwalają na ocenę, jakie są upodobania
			Użytkowników lub Prawników, a także jak można ulepszyć
			stronę internetową dla Użytkownika lub Prawnika. Zazwyczaj
			zawierają nazwę strony internetowej, z której pochodzą, czas
			ich istnienia (tzn. przechowywania na urządzeniu końcowym)
			oraz unikalny numer do identyfikacji przeglądarki, z jakiej
			następuje połączenie ze stroną internetową.
		</li>
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			Ciasteczkach Zewnętrznych - należy przez to rozumieć
			Ciasteczka zamieszczane przez podmioty inne niż
			Administrator, za pośrednictwem ich serwisów internetowych.
			Poszczególne podmioty są operatorem zainicjowanych przez
			siebie plików Cookies a&#xa0;Administrator może nie mieć
			wpływu na nie i nie odpowiada za ich dobór oraz stosowaną
			politykę.
		</li>
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			Urządzeniu - należy przez to rozumieć każde elektroniczne
			urządzenie za pośrednictwem, którego Użytkownik lub Prawnik
			uzyskuje dostęp do serwisu William.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§3</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">
			Typy wykorzystywanych Ciasteczek
		</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">
			1. W ramach systemu William wykorzystywane są następujące
			typy Ciasteczek:
		</span>
	</p>
	<ol class="awlist2" style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			Ciasteczka sesyjne - pliki tymczasowe, pozostające na
			urządzeniu końcowym Użytkownika lub Prawnika, aż do
			wylogowania z serwisu William lub wyłączenia oprogramowania
			służącego dostępowi do tego serwisu;
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			Ciasteczka stałe - pliki pozostające na urządzeniu końcowym
			Użytkownika lub Prawnika przez czas określony w parametrach
			tych plików albo do momentu ich ręcznego usunięcia przez
			Użytkownika lub Prawnika.
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">
			Zastosowanie w ramach systemu William znajdują również
			Ciasteczka Zewnętrzne.
		</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">
			2. Oprócz Ciasteczek Administrator może również
			wykorzystywać inne technologie i rozwiązania techniczne
			pozwalające uzyskać dostęp do informacji przechowywanych w
			urządzeniu końcowym Użytkownika lub Prawnika, a których
			działanie jest korzystne dla funkcjonowania serwisu William.
		</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">
			3. Administrator korzysta z oprogramowania analitycznego
			podmiotów trzecich (np. Google Marketing Platform, Piksel
			Facebooka, Insight Tag LinkedIn, Microsoft Clarity)
			zamieszczające w urządzeniu końcowym Użytkownika lub
			Prawnika dane umożliwiające zbieranie informacji o
			Użytkownikach lub Prawnikach.
		</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§4</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Cel wykorzystania</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">
			Serwis William używa Ciasteczek w celu:
		</span>
	</p>
	<ol class="awlist4" style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			uwierzytelniania i utrzymania stanu sesji,
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			analizy wyświetleń (analiza informacji wyświetlanych przez
			Użytkowników lub Prawników bez zbierania danych osobowych,
			służąca ustalaniu preferencji i tworzeniu statystyk),
		</li>
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			ustalania lokalizacji Użytkownika lub Prawnika (dostosowanie
			funkcji serwisu William oraz treści wyświetlanych informacji
			do lokalizacji Użytkownika lub Prawnika),
		</li>
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			zapewnienia bezpieczeństwa (weryfikacja autentyczności
			połączenia),
		</li>
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			optymalizacji działania serwisu (zapamiętywanie ustawień).
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§5</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">Ciasteczka Zewnętrzne</span>
	</p>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">
			Administrator wykorzystuje Ciasteczka Zewnętrzne do:
		</span>
	</p>
	<ol class="awlist5" style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			zbierania ogólnych i anonimowych danych statycznych za
			pośrednictwem narzędzi analitycznych (np. Google Analitycs),
		</li>
	</ol>
	<ol start="2" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			analizy procesu korzystania przez Prawników z czatu
			obsługiwanego przez sztuczną inteligencję (Microsoft
			Clarity),
		</li>
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			prezentowania na stronach internetowych treści
			multimedialnych, które są pobierane z&#xa0;zewnętrznych
			serwisów internetowych (np. Google Maps),
		</li>
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			ułatwienia komunikacji lub popularyzacji serwisu William za
			pomocą serwisów narzędziowych i społecznościowych (np.
			przycisk Facebook, przycisk Instagram, przycisk LinkedIn),
		</li>
		<li
			class="Tre"
			style="margin-left:18pt; text-indent:-18pt; text-align:justify; letter-spacing:normal; vertical-align:baseline"
		>
			<span style="width:8.84pt; font:7pt 'Times New Roman'; display:inline-block">
				&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; - 
			</span>
			prezentowania treści w ramach serwisu William, które są
			pobierane z zewnętrznego serwisu internetowego (np.
			obsługującego publiczne rejestry).
		</li>
	</ol>
	<p class="Tre" style="text-align:justify">
		<span style="letter-spacing:normal">&#xa0;</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">§6</span>
	</p>
	<p class="Tre" style="text-align:center">
		<span style="letter-spacing:normal">
			Ustawienia przeglądarki
		</span>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline"
		>
			Standardowo oprogramowanie służące do przeglądania stron
			internetowych domyślnie dopuszcza umieszczanie Ciasteczek na
			urządzeniu końcowym. Ustawienia te mogą zostać zmienione w
			taki sposób, aby blokować automatyczną obsługę Ciasteczek w
			ustawieniach przeglądarki internetowej bądź informować o ich
			każdorazowym przesłaniu na urządzenie Użytkownika lub
			Prawnika. Szczegółowe informacje o możliwości i sposobach
			obsługi Ciasteczek dostępne są w ustawieniach przeglądarki
			internetowej.
		</li>
	</ol>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li
			class="Tre"
			style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline"
		>
			Użytkownik lub Prawnik może w każdej chwili usunąć
			Ciasteczka korzystając z dostępnych funkcji
			w&#xa0;przeglądarce internetowej, której używa.
		</li>
		<li
			class="Tre"
			style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline"
		>
			Wyłączenie w przeglądarce internetowej opcji pozwalającej na
			zapisywanie Ciasteczek zasadniczo nie uniemożliwia
			korzystania z serwisu William, jednak może spowodować
			utrudnienia i&#xa0;zmniejszyć komfort korzystania ze stron
			internetowych.
		</li>
		<li
			class="Tre"
			style="margin-left:13.25pt; text-align:justify; padding-left:4.75pt; letter-spacing:normal; vertical-align:baseline"
		>
			Użytkownik lub Prawnik, który korzysta z danego urządzenia
			końcowego wspólnie z&#xa0;innymi osobami, powinien pamiętać
			o wylogowaniu się po każdym zakończonym korzystaniu z
			serwisu William.
		</li>
	</ol>
</div>`;

export const languages = [
	{
		code: "en",
		locale: "en-US",
		longNameEng: "english",
		longNameOrg: "english",
		flagString: "🇬🇧",
		flagSVG: <Flag code="GBR" />,
	},
	{
		code: "de",
		locale: "de-DE",
		longNameEng: "german",
		longNameOrg: "deutsch",
		flagString: "🇩🇪",
		flagSVG: <Flag code="DEU" />,
	},
	{
		code: "fr",
		locale: "fr-FR",
		longNameEng: "french",
		longNameOrg: "français",
		flagString: "🇫🇷",
		flagSVG: <Flag code="FRA" />,
	},
	{
		code: "es",
		locale: "es-ES",
		longNameEng: "spanish",
		longNameOrg: "español",
		flagString: "🇪🇸",
		flagSVG: <Flag code="ESP" />,
	},
	{
		code: "it",
		locale: "it-IT",
		longNameEng: "italian",
		longNameOrg: "italiano",
		flagString: "🇮🇹",
		flagSVG: <Flag code="ITA" />,
	},
	{
		code: "nl",
		locale: "nl-NL",
		longNameEng: "dutch",
		longNameOrg: "nederlands",
		flagString: "🇳🇱",
		flagSVG: <Flag code="NLD" />,
	},
	{
		code: "pl",
		locale: "pl-PL",
		longNameEng: "polish",
		longNameOrg: "polski",
		flagString: "🇵🇱",
		flagSVG: <Flag code="POL" />,
	},
	{
		code: "uk",
		locale: "uk-UA",
		longNameEng: "ukrainian",
		longNameOrg: "українська",
		flagString: "🇺🇦",
		flagSVG: <Flag code="UKR" />,
	},
	{
		code: "ro",
		locale: "ro-RO",
		longNameEng: "romanian",
		longNameOrg: "română",
		flagString: "🇷🇴",
		flagSVG: <Flag code="ROU" />,
	},
	{
		code: "hu",
		locale: "hu-HU",
		longNameEng: "hungarian",
		longNameOrg: "magyar",
		flagString: "🇭🇺",
		flagSVG: <Flag code="HUN" />,
	},
	{
		code: "pt",
		locale: "pt-PT",
		longNameEng: "portuguese",
		longNameOrg: "português",
		flagString: "🇵🇹",
		flagSVG: <Flag code="PRT" />,
	},
	{
		code: "sv",
		locale: "sv-SE",
		longNameEng: "swedish",
		longNameOrg: "svenska",
		flagString: "🇸🇪",
		flagSVG: <Flag code="SWE" />,
	},
	{
		code: "nl-BE",
		locale: "nl-BE",
		longNameEng: "flemish",
		longNameOrg: "vlaams",
		flagString: "🇧🇪",
		flagSVG: <Flag code="BEL" />,
	},
	{
		code: "cs",
		locale: "cs-CZ",
		longNameEng: "czech",
		longNameOrg: "čeština",
		flagString: "🇨🇿",
		flagSVG: <Flag code="CZE" />,
	},
	{
		code: "sr",
		locale: "sr-RS",
		longNameEng: "serbian",
		longNameOrg: "српски",
		flagString: "🇷🇸",
		flagSVG: <Flag code="SRB" />,
	},
	{
		code: "sk",
		locale: "sk-SK",
		longNameEng: "slovak",
		longNameOrg: "slovenčina",
		flagString: "🇸🇰",
		flagSVG: <Flag code="SVK" />,
	},
	{
		code: "hr",
		locale: "hr-HR",
		longNameEng: "croatian",
		longNameOrg: "hrvatski",
		flagString: "🇭🇷",
		flagSVG: <Flag code="HRV" />,
	},
	{
		code: "da",
		locale: "da-DK",
		longNameEng: "danish",
		longNameOrg: "dansk",
		flagString: "🇩🇰",
		flagSVG: <Flag code="DNK" />,
	},
	{
		code: "no",
		locale: "no-NO",
		longNameEng: "norwegian",
		longNameOrg: "norsk",
		flagString: "🇳🇴",
		flagSVG: <Flag code="NOR" />,
	},
	{
		code: "lt",
		locale: "lt-LT",
		longNameEng: "lithuanian",
		longNameOrg: "lietuvių",
		flagString: "🇱🇹",
		flagSVG: <Flag code="LTU" />,
	},
	{
		code: "lv",
		locale: "lv-LV",
		longNameEng: "latvian",
		longNameOrg: "latviešu",
		flagString: "🇱🇻",
		flagSVG: <Flag code="LVA" />,
	},
	{
		code: "mo",
		locale: "mo-MD",
		longNameEng: "moldovan",
		longNameOrg: "moldovenească",
		flagString: "🇲🇩",
		flagSVG: <Flag code="MDA" />,
	},
	{
		code: "et",
		locale: "et-EE",
		longNameEng: "estonian",
		longNameOrg: "eesti",
		flagString: "🇪🇪",
		flagSVG: <Flag code="EST" />,
	},
	{
		code: "sl",
		locale: "sl-SI",
		longNameEng: "slovenian",
		longNameOrg: "slovenščina",
		flagString: "🇸🇮",
		flagSVG: <Flag code="SVN" />,
	},
	{
		code: "fi",
		locale: "fi-FI",
		longNameEng: "finnish",
		longNameOrg: "suomi",
		flagString: "🇫🇮",
		flagSVG: <Flag code="FIN" />,
	},
	{
		code: "ja",
		locale: "ja-JP",
		longNameEng: "Japanese",
		longNameOrg: "日本語",
		flagString: "🇯🇵",
		flagSVG: <Flag code="JPN" />,
	}
];
