import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import Image from "../Image";
import { appUrl } from "../../conts";
import { useTranslation } from "react-i18next";
import { LanguageDropdown } from "../LanguageDropdown/LanguageDropdown";

const socials2 = [
	{
		title: "LinkedIn",
		url: "https://www.linkedin.com/company/william-ai/",
	},
	{
		title: "Facebook",
		url: "https://www.facebook.com/william.legalai",
	},
];

const Headers = () => {
	const [visibleNav, setVisibleNav] = useState(false);

	const scrollToId = (id) => {
		const element = document.getElementById(id);
		element.scrollIntoView({ behavior: "smooth" });
	};

	const { t } = useTranslation();

	const navLinks = [
		{
			title: t("functions"),
			url: "funkcje",
		},
		{
			title: t("howItWorks"),
			url: "jak-to-dziala",
		},
		{
			title: t("pricing"),
			url: "cennik",
		},
		{
			title: t("opinions"),
			url: "opinie",
		},
	];

	return (
		<header className={styles.header}>
			<div className={cn("container", styles.container)}>
				<Link className={styles.logo} to="/" onClick={() => setVisibleNav(false)}>
					<Image className={styles.pic} src="/gfx/logo.png" alt="William" maxWidth={166} />
				</Link>
				<div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
					<nav className={styles.nav}>
						{navLinks.map((x, index) => (
							<button className={styles.link} key={index} onClick={() => scrollToId(x.url)}>
								{x.title}
							</button>
						))}
					</nav>
				</div>

				<LanguageDropdown />
				<a className={cn("button-stroke button-small", styles.button)} href={appUrl}>
					{t("tryForFree")}
				</a>
				<button className={cn(styles.burger, { [styles.active]: visibleNav })} onClick={() => setVisibleNav(!visibleNav)}></button>
			</div>
		</header>
	);
};

export default Headers;
