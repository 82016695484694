import React, { createContext, useCallback, useContext, useEffect, useState, startTransition } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { languages } from "./conts";
import { localStorageHelper } from "./localStorageHelper";

const LanguageContext = createContext(undefined);

export const LanguageProvider = ({ children }) => {
	const { i18n } = useTranslation();
	const [language, setLanguageState] = useState(i18n.language);

	const currentLanguage = languages.find((lang) => lang.code === language) || languages[0];

	const loadNamespace = useCallback(
		async (lang, ns) => {
			if (!i18n.hasResourceBundle(lang, ns)) {
				await i18n.loadNamespaces(ns, (error) => {
					if (error) {
						//console.error("Error loading clerk namespace:", error);
					}
				});
			}
			const resourceBundle = i18n.getResourceBundle(lang, ns);
			//console.log("Resource bundle loaded:", resourceBundle);
			return resourceBundle;
		},
		[i18n]
	);

	useEffect(() => {
		const handleLanguageChange = (lang) => {
			startTransition(() => {
				setLanguageState(lang);
				loadNamespace(lang, "clark");
			});
		};

		i18n.on("languageChanged", handleLanguageChange);
		return () => {
			i18n.off("languageChanged", handleLanguageChange);
		};
	}, [i18n, loadNamespace]);

	useEffect(() => {
		if (!localStorageHelper.languageSystem.hasValue()) {
			localStorageHelper.languageSystem.set(i18n.language);
		} else {
			const storedLanguage = localStorageHelper.languageSystem.get();
			if (storedLanguage) {
				startTransition(() => {
					setLanguageState(storedLanguage);
					i18n.changeLanguage(storedLanguage);
				});
			}
		}
	}, [i18n]);

	const setLanguage = (lang) => {
		startTransition(() => {
			setLanguageState(lang);
			i18n.changeLanguage(lang);
			localStorageHelper.languageSystem.set(lang);

			const cl = languages.find((l) => l.code === lang) || languages[0];

			loadNamespace(lang, "common");
			const langChanged = i18n.getResourceBundle(lang, "common")?.langChanged;

			toast.success(`${langChanged} - ${cl.longNameOrg}`);
		});
	};

	return <LanguageContext.Provider value={{ language: currentLanguage, setLanguage, allLanguages: languages }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
	const context = useContext(LanguageContext);
	if (!context) {
		throw new Error("useLanguage must be used within a LanguageProvider");
	}
	return context;
};
