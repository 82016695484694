import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./components/Home";
import { clarity } from "react-microsoft-clarity";
import { useEffect } from "react";
import { termsHTML, cookiesHTML, privacyHTML } from "./conts";
import "./i18n"; // Import the i18n configuration

function App() {
	useEffect(() => {
		if (!window.location.href.includes("localhost")) {
			clarity.init("hahdntao6n");
		}
	}, []);

	return (
		<Router>
			<Routes>
				<Route
					path="/"
					element={
						<Page>
							<Home />
						</Page>
					}
				/>
				<Route
					path="/terms"
					element={
						<div
							style={{ padding: "10px 30px" }}
							dangerouslySetInnerHTML={{
								__html: termsHTML,
							}}
						/>
					}
				/>
				<Route
					path="/cookies"
					element={
						<div
							style={{ padding: "10px 30px" }}
							dangerouslySetInnerHTML={{
								__html: cookiesHTML,
							}}
						/>
					}
				/>
				<Route
					path="/privacy"
					element={
						<div
							style={{ padding: "10px 30px" }}
							dangerouslySetInnerHTML={{
								__html: privacyHTML,
							}}
						/>
					}
				/>
			</Routes>
		</Router>
	);
}

export default App;
