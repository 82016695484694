import React from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Lifestyle.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";
import { appUrl } from "../../../conts";

import { useTranslation } from "react-i18next";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => <button {...props}>{children}</button>;

const Lifestyle = () => {
	const { t } = useTranslation();
	const items = [
		{
			title: t("lifeStyle1"),
			image: "/gfx/chatsc1.webp",
			content: t("lifeStyle1Content"),
		},
		{
			title: t("lifeStyle2"),
			image: "/gfx/chatsc2.webp",
			content: t("lifeStyle2Content"),
		},
		{
			title: t("lifeStyle3"),
			image: "/gfx/chatsc3.webp",
			content: t("lifeStyle3Content"),
		},
	];

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		nextArrow: (
			<SlickArrow>
				<Icon name="arrow-next" size="14" />
			</SlickArrow>
		),
		prevArrow: (
			<SlickArrow>
				<Icon name="arrow-prev" size="14" />
			</SlickArrow>
		),
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 100000,
				settings: "unslick",
			},
		],
	};

	return (
		<div id="jak-to-dziala" className={cn("section-pb", styles.section)}>
			<div className={cn("container", styles.container)}>
				<div className={styles.head} style={{ paddingTop: 112 }}>
					<h2 className={cn("h2", styles.title)}>{t("lifeStyle_title")}</h2>
					<div className={styles.info}></div>
					<a className={cn("button-stroke", styles.button)} href={appUrl}>
						<span>{t("testToday")}</span>
						<Icon name="arrow-right" size="10" />
					</a>
				</div>
				<div className={styles.wrap}>
					<Slider className={cn("lifestyle-slider", styles.slider)} {...settings}>
						{items.map((x, index) => (
							<ScrollParallax className={styles.item} key={index}>
								<div className={styles.row}>
									<div className={styles.col}>
										<div className={styles.details}>
											<div className={styles.number}>0{index + 1}.</div>
											<div className={styles.category}>{x.title}</div>
											<div className={styles.content}>{x.content}</div>
										</div>
									</div>
									<div className={styles.col}>
										<img src={x.image} alt="Lifestyle" />
									</div>
								</div>
							</ScrollParallax>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default Lifestyle;
