import React from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";
import { appUrl } from "../../../conts";
import { useTranslation } from "react-i18next";

const Hero = () => {
	const { t } = useTranslation();
	return (
		<div className={styles.hero}>
			<div className={cn("container", styles.container)}>
				<ScrollParallax className={styles.wrap}>
					<div className={cn("stage", styles.stage)}>{t("hero_stage")}</div>
					<h1 className={cn("h1", styles.title)}>{t("hero_title")}</h1>
					<div className={styles.text}>{t("hero_content1")}</div>
					<div className={styles.text2}>{t("hero_content2")}</div>
					<div className={styles.btns}>
						<a className={cn("button", styles.button)} href={appUrl}>
							{t("useWilliam")}
						</a>
					</div>
					<div style={{ marginTop: 20, display: "flex", gap: 10 }}>
						<img src="/gfx/msstartup.webp" alt="msstartup" style={{ width: "calc(50% - 5px)" }} />
						<img src="/gfx/nvidia.webp" alt="msstartup" style={{ width: "calc(50% - 5px)" }} />
					</div>
				</ScrollParallax>

				<Image src="/gfx/chatshero.webp" alt="Watch" maxWidth={600} />
			</div>
		</div>
	);
};

export default Hero;
